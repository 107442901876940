$maxPageWidth: 1000px;

// ----------------------------------------------------------------------------
// === *** ===
// ----------------------------------------------------------------------------

.lcei-mfo-page .lcei-mfo-certificate-order-search-page {
    ion-searchbar {
        input {
            text-transform: uppercase;
            font-size: var(--xs-font-size-medium) !important;
        }
    }
}

.lcei-mfo-service-item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: var(--xsi-border-radius-default);
    padding: 15px;
    overflow: hidden;

    &.lcei-mfo-service-item-card-primary {
        background-color: var(--ion-color-primary);
    }

    &.lcei-mfo-service-item-card-secondary {
        background-color: var(--ion-color-secondary);

    }

    .lcei-mfo-service-item-card-title {
        opacity: 0.7;
        margin-top: 5px;
    }

    .lcei-mfo-service-item-card-title-primary {
        color: var(--ion-color-primary-contrast);
    }

    .lcei-mfo-service-item-card-title-secondary {
        color: var(--ion-color-secondary-contrast);
    }
}

.lcei-mfo-settings.ion-page, .lcei-mfo-certificate-order-search.ion-page {
    background-color: var(--xsi-secondary-background-color-tint);

    ion-content, ion-header, ion-footer {
        background-color: var(--xsi-secondary-background-color-tint);
        --ion-background-color: var(--xsi-secondary-background-color-tint);
    }

    ion-card {
        width: 100%;
        border: none !important;
        box-shadow: none !important;
        margin-left: 0 !important;
        margin-right: 0 !important;

        ion-item {
            --padding-start: 15px;
        }

        ion-label {
            font-size: calc(var(--xs-font-size-medium) - 3px) !important;
            line-height: 1.4;
        }
    }
}

.lcei-mfo-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    max-width: $maxPageWidth;
}

.ion-page {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

// ---------------------------------------------------------------------------------------
// --- Temp ---
// ---------------------------------------------------------------------------------------

.xsi-padding-start-10 {
    --padding-start: 10px;
}

.xsi-padding-start-10-imp {
    --padding-start: 10px !important;
}

.xsi-input-select ion-select {
    --padding-top: 5px;
    --padding-bottom: 6px;
    --padding-end: 18px;
}

.xsi-header-stripe-secondary-5 {
    border-left: 5px solid var(--ion-color-secondary);
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.xsi-header-stripe-light-5 {
    border-left: 5px solid var(--ion-color-light);
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.xsi-header-stripe-medium-5 {
    border-left: 5px solid var(--ion-color-medium);
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.xsi-header-stripe-discrete-darker-5 {
    border-left: 5px solid var(--xsi-color-discrete-darker);
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.xsi-border-top-discrete-darker {
    border-top: 1px solid var(--xsi-color-discrete-darker);
}

.xsi-background-color-discrete-darker {
    background-color: var(--xsi-color-discrete-darker);
}

.xsi-no-result-found-background-discrete {
    padding: 5px 25px 4px;
    background-color: var(--xsi-color-discrete);
    border-radius: 12px;
}

.xsi-chip-background-small-discrete {
    padding: 1px 25px 1px;
    background-color: var(--xsi-color-discrete);
    border-radius: 50px;
    height: 20px;
}

.xsi-chip-background-small-primary {
    padding: 1px 25px 1px;
    background-color: var(--ion-color-primary);
    border-radius: 50px;
    height: 20px;
}

.xsi-chip-background-small-secondary {
    padding: 1px 25px 1px;
    background-color: var(--ion-color-secondary);
    border-radius: 50px;
    height: 20px;
}

.xsi-chip-background-small-secondary-tint {
    padding: 1px 25px 1px;
    background-color: var(--ion-color-secondary-tint);
    border-radius: 50px;
    height: 20px;
}

.xsi-chip-background-small-primary-tint {
    padding: 1px 25px 1px;
    background-color: var(--ion-color-primary-tint);
    border-radius: 50px;
    height: 20px;
}

.xsi-color-secondary-contrast {
    color: var(--ion-color-secondary-contrast);
}

.xsi-color-secondary-contrast-imp {
    color: var(--ion-color-secondary-contrast) !important;
}

.xsi-inner-padding-end-0 {
    --inner-padding-end: 0;
}

// -----------------------------------------------------------------------------------------------
// --- Ion Item ---
// -----------------------------------------------------------------------------------------------

ion-item:last-child {
    --inner-border-width: 0;
}

.xsi-ion-item-no-spacing {
    //xsi-padding-start-0-imp xsi-inner-padding-end-30 xsi-inner-padding-end-0
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;

    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
}

// -----------------------------------------------------------------------------------------------
// --- Theme ---
// -----------------------------------------------------------------------------------------------

small {
    font-size: var(--xs-font-size-small);
}

ion-chip {
    margin: 0;
}

// -----------------------------------------------------------------------------------------------
// --- XS ---
// -----------------------------------------------------------------------------------------------

.xs-line-height-1-20 {
    line-height: 1.2;
}

.xs-z-index-minus-10 {
    z-index: -10;
}

.xs-z-index-minus-1 {
    z-index: -1;
}

.xs-z-index-minus-0 {
    z-index: 0;
}