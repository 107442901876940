/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* === XS === */
@import '@iro-xs/xs-styles/scss/xs-default-variables';
@import '@iro-xs/xs-styles/scss/xs-default-schemes';
@import './styles/xs-ionic';
@import '@iro-xs/xs-styles/scss/xs-swiper';
@import '@iro-xs/xs-styles/scss/xs-input-pure';
/* === XS Payment === */
@import '@iro-xs/xs-payment-base/assets/scss/xs-payment-base';
@import '@iro-xs/xs-payment-checkout/assets/scss/xs-payment-checkout';
/* === XSI === */
@import '@iro-xs/xs-ionic-styles/scss/xsi-core';
@import '@iro-xs/xs-ionic-styles/scss/xsi-input-digit';
@import '@iro-xs/xs-ionic-styles/scss/xsi-widget';
/* === LCE === */
@import './styles/lcei-mfo-variables';
@import '@lce/ionic/shared/assets/scss/lcei-shared';
@import './styles/lcei-mfo';
