$xsBreakPointSM: 576px; // 576px 350px
$xsBreakPointMD: 768px;
$xsBreakPointLG: 992px; //1300px;
$xsBreakPointXL: 1200px; //1600px;

:root {
    --xs-breakpoint-sm: #{$xsBreakPointSM};
    --xs-breakpoint-md: #{$xsBreakPointMD};
    --xs-breakpoint-lg: #{$xsBreakPointLG};
    --xs-breakpoint-xl: #{$xsBreakPointXL};
}

@font-face {
    font-family: 'FontAwesome';
    src: url('./fa-solid-900.ttf') format('truetype');
}

@import '@iro-xs/xs-styles/scss/xs-constant';
@import '@iro-xs/xs-styles/scss/xs-mixin';
@import '@iro-xs/xs-styles/scss/xs-spacing';
@import '@iro-xs/xs-styles/scss/xs-sizing';
@import '@iro-xs/xs-styles/scss/xs-typography';
@import '@iro-xs/xs-styles/scss/xs-flex';
@import '@iro-xs/xs-styles/scss/xs-card';
@import '@iro-xs/xs-styles/scss/xs-color';
@import '@iro-xs/xs-styles/scss/xs-background';
@import '@iro-xs/xs-styles/scss/xs-border';
@import '@iro-xs/xs-styles/scss/xs-utils';
@import '@iro-xs/xs-styles/scss/xs-functions';
@import '@iro-xs/xs-styles/scss/xs-loader';
@import '@iro-xs/xs-styles/scss/xs-amount';