:root {
    --xsi-font-size-extra-large: var(--xs-font-size-extra-large);
    --xsi-font-size-large: var(--xs-font-size-large);
    --xsi-font-size-medium: var(--xs-font-size-medium);
    --xsi-font-size-default: var(--xs-font-size-default);
    --xsi-font-size-small: var(--xs-font-size-small);
    --xsi-font-size-extra-small: var(--xs-font-size-extra-small);

    --xsi-border-radius-default: 12px;
    --xsi-modal-border-radius: 15px;

    --xsi-input-digit-font-size: 175%;
    --xsi-input-digit-border-radius: 10px;

    --xs-border-radius-default: var(--xsi-border-radius-default);

    --xs-color-discrete-darker: darken(var(--xs-color-discrete), 25%);
}

// ----------------------------------------------------------------------------------------------------
// === Light mode CSS Variables ===
// ----------------------------------------------------------------------------------------------------

body[scheme="light"] {
    --xsi-secondary-background-color: #F0F0F5;
    --xsi-secondary-background-color-rgb: rgb(240, 240, 245);
    --xsi-secondary-background-color-tint: #F0F0F5;
    --xsi-secondary-background-color-tint-rgba: rgba(240, 240, 245, 0.3);

    --xsi-color-discrete: var(--xs-color-discrete);
    --xsi-color-discrete-darker: rgb(212, 218, 223); //hsl(from var(--xsi-color-discrete) h s calc(l - 5));

    --ion-text-color: rgb(53, 64, 78);
    --ion-background-color: #ffffff;

    --ion-toolbar-background: var(--ion-background-color);
    --ion-tab-bar-background: var(--ion-background-color);
    --ion-item-background: var(--ion-background-color);
    --ion-card-background: var(--ion-background-color);

    /** primary **/
    --ion-color-primary: #e5883c;
    --ion-color-primary-rgb: 230, 145, 56;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #c46705;
    --ion-color-primary-tint: #f79a38;

    /** secondary **/
    --ion-color-secondary: #607d8b;
    --ion-color-secondary-rgb: 96, 125, 139;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #475c67;
    --ion-color-secondary-tint: #8ca4b0;

    /** tertiary **/
    --ion-color-tertiary: #C0C0C0;
    --ion-color-tertiary-rgb: 192, 192, 192;
    --ion-color-tertiary-contrast: #181818;
    --ion-color-tertiary-contrast-rgb: 24, 24, 24;
    --ion-color-tertiary-shade: #a9a9a9;
    --ion-color-tertiary-tint: #c6c6c6;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #f60046;
    --ion-color-danger-rgb: 246, 0, 70;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #c40038;
    --ion-color-danger-tint: #f61958;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #D3D3D3;
    --ion-color-light-rgb: 211, 211, 211;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #bababa;
    --ion-color-light-tint: #d7d7d7;

    /** Steps **/
    --ion-color-step-50: #fcfcfc;
    --ion-color-step-100: #fcfcfc;
    --ion-color-step-150: #fcfcfc;
    --ion-color-step-200: #fcfcfc;
    --ion-color-step-250: #f9f9f9;

    --ion-color-step-300: #f7f7f7;
    --ion-color-step-350: #f4f4f4;
    --ion-color-step-400: #f2f2f2;
    --ion-color-step-450: #efefef;
    --ion-color-step-500: #ececec;

    // --- XS Primary ---
    --xs-color-primary: var(--ion-color-primary);
    --xs-color-primary-contrast: var(--ion-color-primary-contrast);

    // --- XS Secondary ---
    --xs-color-secondary: var(--ion-color-secondary);
    --xs-color-secondary-contrast: var(--ion-color-secondary-contrast);

    // --- XS Tertiary ---
    --xs-color-tertiary: var(--ion-color-tertiary);
    --xs-color-tertiary-contrast: var(--ion-color-tertiary-contrast);
}

// ----------------------------------------------------------------------------------------------------
// === Dark mode CSS Variables ===
// ----------------------------------------------------------------------------------------------------

body[scheme="dark"] {
    --xsi-secondary-background-color: #090d10;
    --xsi-secondary-background-color-rgb: rgb(9, 13, 16);
    --xsi-secondary-background-color-tint: #090d10;
    --xsi-secondary-background-color-tint-rgba: rgba(240, 240, 245, 0.3);

    --xs-color-discrete: #1c2730;
    --xsi-color-discrete: var(--xs-color-discrete);

    --ion-text-color: rgb(235, 235, 235);

    --ion-background-color: #12191F;

    --ion-toolbar-background: var(--ion-background-color);
    --ion-tab-bar-background: var(--ion-background-color);
    --ion-item-background: var(--ion-background-color);
    --ion-card-background: var(--ion-background-color);

    /** primary **/
    --ion-color-primary: #e5883c;
    --ion-color-primary-rgb: 230, 145, 56;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #c46705;
    --ion-color-primary-tint: #f79a38;

    --ion-color-secondary: #607d8b;
    --ion-color-secondary-rgb: 96, 125, 139;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #475c67;
    --ion-color-secondary-tint: #8ca4b0;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #424242;
    --ion-color-light-rgb: 66, 66, 66;
    --ion-color-light-contrast: #D3D3D3;
    --ion-color-light-contrast-rgb: 211, 211, 211;
    --ion-color-light-shade: #3b3b3b;
    --ion-color-light-tint: #545454;

    /** Steps **/
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;

    // --- XS Primary ---
    --xs-color-primary: var(--ion-color-primary);
    --xs-color-primary-contrast: var(--ion-color-primary-contrast);

    // --- XS Secondary ---
    --xs-color-secondary: var(--ion-color-secondary);
    --xs-color-secondary-contrast: var(--ion-color-secondary-contrast);

    // --- XS Tertiary ---
    --xs-color-tertiary: var(--ion-color-tertiary);
    --xs-color-tertiary-contrast: var(--ion-color-tertiary-contrast);
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

//.ios body[scheme="dark"] {
//    --ion-background-color: #12191F;
//    --ion-background-color-rgb: 18, 25, 31;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255, 255, 255;
//
//    --ion-item-background: var(--ion-background-color);
//    --ion-toolbar-background: var(--ion-background-color);
//    --ion-tab-bar-background: var(--ion-background-color);
//    --ion-card-background: var(--ion-background-color);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//}
//
//.ios body[scheme="dark"] ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

//.md body[scheme="dark"] {
//    --ion-background-color: #2d2d2d;
//    --ion-background-color-rgb: 45, 45, 45;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255, 255, 255;
//
//    --ion-border-color: #222222;
//
//    --ion-item-background: var(--ion-background-color);
//    --ion-toolbar-background: var(--ion-background-color);
//    --ion-tab-bar-background: var(--ion-background-color);
//    --ion-card-background: var(--ion-background-color);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//}
