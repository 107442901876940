// ---------------------------------------------------------------------------------------------
// --- Variables ---
// ---------------------------------------------------------------------------------------------

// --- User Status ---
$onlineColor: rgb(89, 207, 17);
$offlineColor: gray;
$busyColor: var(--xs-color-danger);
$awayColor: orange;
$neverConnectedColor: black;

// ---------------------------------------------------------------------------------------------
// --- Background Icon ---
// ---------------------------------------------------------------------------------------------

.lcei-background-icon-recent::after {
    content: "\f017";
}

// ---------------------------------------------------------------------------------------------
// --- Certificate Order ---
// ---------------------------------------------------------------------------------------------

.lcei-certificate-order-partial-stripe {
    border-left: 12px solid var(--ion-color-secondary-tint);
}

.lcei-certificate-order-record {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    max-width: 750px;
    padding-top: 25px;
    padding-bottom: 125px;

    .lcei-certificate-order-record-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        height: 100%;
        align-items: center;
    }
}

.lcei-certificate-order-number-of-copies {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid var(--xsi-color-discrete);
    border-radius: 6px;
    font-size: var(--xs-font-size-medium) !important;
}

.lcei-certificate-order-status-state {
    display: block;
    border-radius: 50%;
    width: 9px;
    height: 9px;
}

// ---------------------------------------------------------------------------------------------
// --- User Status---
// ---------------------------------------------------------------------------------------------

/* === Background === */
.lcei-user-status-background-online {
    background: $onlineColor;
}

.lcei-user-status-background-offline {
    background: $offlineColor;
}

.lcei-user-status-background-busy {
    background: $busyColor;
}

.lcei-user-status-background-away {
    background: $awayColor;
}

.lcei-user-status-background-neverConnected {
    background: $neverConnectedColor;
}

/* === Color === */
.lcei-user-status-color-online {
    color: $onlineColor;
}

.lcei-user-status-color-offline {
    color: $offlineColor;
}

.lcei-user-status-color-busy {
    color: $busyColor;
}

.lcei-user-status-color-away {
    color: $awayColor;
}

.lcei-user-status-color-neverConnected {
    color: $neverConnectedColor;
}

// ---------------------------------------------------------------------------------------------
// --- App Name ---
// ---------------------------------------------------------------------------------------------

.lcei-app-name {
    width: fit-content;

    .lcei-app-name-image {
        position: relative;
        width: 100%;
        min-width: 150px;
        max-width: 250px;

        img {
            width: 100%;
            height: 100%;
        }

        .lcei-app-name-facility-name {
            position: absolute;
            left: 0;
            bottom: -28px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: var(--ion-color-primary-contrast);
            background-color: var(--ion-color-primary-tint);
            font-size: var(--xs-font-size-small);
            font-weight: 500;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 3px;
            padding-bottom: 3px;
            border-radius: 50px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .lcei-app-name-html {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 7px;
        padding-bottom: 7px;
        border-radius: 10px;
        color: var(--ion-color-primary-contrast);
        background-color: var(--ion-color-primary);
        max-width: 100px;
        font-weight: 700;
        font-size: var(--xs-font-size-large);
    }
}

// ---------------------------------------------------------------------------------------------
// --- Certificate Order Icon ---
// ---------------------------------------------------------------------------------------------

.lcei-certificate-icon-outline {
    display: block;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url('/assets/icons/lce-certificate-outline.svg');
    mask-image: url('/assets/icons/lce-certificate-outline.svg');
    background-color: var(--ion-color-primary);
}

.lcei-certificate-icon {
    &:after {
        content: ' ';
        display: block;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url('/assets/icons/lce-certificate.svg');
        mask-image: url('/assets/icons/lce-certificate.svg');
        background-color: var(--ion-color-primary);
    }
}

$CERTIFICATE_ORDER_ICON_SIZES: 12, 13, 14, 14, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 100, 125, 150, 175, 200;

@each $size in $CERTIFICATE_ORDER_ICON_SIZES {
    .lcei-certificate-icon-size-#{$size}:after {
        height: $size + px;
        width: $size + px;
    }
    .lcei-certificate-icon-size-#{$size}-imp:after {
        height: $size + px !important;
        width: $size + px !important;
    }
}
